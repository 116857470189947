.image-bot_section {
  position: relative;
  /* height: 100vh; */
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  animation: opacity-in 0.1s;
  background-color: black;
  /* overflow: cover; */
}

.blink {
  animation: blink 1s infinite;
}

.image-bot_section::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 0) {
  .image-bot_section img {
    height: 80vh;
  }

  .image-bot_section {
    height: 80vh;
  }
}

@media screen and (min-width: 1000px) {
  .image-bot_section {
    height: 100vh;
  }

  .image-bot_section img {
    height: 100vh;
  }
}

.image-bot_section img {
  position: absolute;
  object-position: top left;
  top: 0;
  left: 0;
  min-width: 100vw;
}

#image-bot_flex::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(to bottom, #000000, transparent);
  z-index: 5;
}

.image-bot_section img#shine {
  z-index: 7;
  animation: opacity-in 6s infinite;
}

.image-bot_section img#background {
  animation: opacity-breathe 4s infinite;
}

@keyframes opacity-in {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes opacity-breathe {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes blink {
  0% {
	opacity: 0.1;
  }

  50% {
	opacity: 0.3;
  }

  100% {
	opacity: 0.1;
  }
}