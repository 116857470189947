.spinner {
  width: 5em;
  height: 5em;
  border-top: 0.5em solid #d18100;
  border-radius: 50%;
  border-right: 0.5em solid transparent;
  margin: auto;
  animation: spinner 2s linear infinite;
  position: relative;
  margin-top: 25%;
}

.circle {
  width: 0.5em;
  height: 0.5em;
  background-color: #d18100;
  border-radius: 50%;
  margin-left: 4.25em;
  margin-top: 0.25em;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
