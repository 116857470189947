@tailwind base;
@tailwind components;
@tailwind utilities;

img,
svg,
video {
  max-width: none;
  height: auto;
}

body {
  background: #000;
}

:root {
  color-scheme: only light;
}
