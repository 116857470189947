header {
  position: fixed;
  justify-content: space-between;
  z-index: 750;
  transition: 0.6s;
}

@media screen and (max-width: 1158px) {
  header {
    display: inline-block;
    text-align: center;
  }
}

@media screen and (min-width: 1158px) {
  header {
    display: flex;
  }
  header nav {
    margin-right: 3em;
	margin-top: 1em;
  }
}

header .logo {
  font-size: clamp(1.8rem, 2.5vw, 4rem);
  text-shadow: 1px 1px black;
  font-family: font;
  margin-left: 0.5em;
}