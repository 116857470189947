.description-carousel {
  display: flex;
  justify-content: center;
  width: 90%;
  height: 200px;
  margin: auto;
  text-align: center;
}

.arrow {
  width: 20%;
  cursor: pointer;
  margin: 20px;
  z-index: 1;
  transition: 0.2s all;
}

.container {
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.4);
  background: url("../../images/Empty.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 90%;
  max-width: 550px;
  margin: auto;
  border: 1px solid transparent;
  font-family: cabin;

  /* background: linear-gradient(to bottom, transparent, #000000); */
}

.arrow:hover {
  opacity: 1;
  transform: scale(1.05);
}

/* @media screen and (min-width: 1000px) {
  .container {
    margin-left: calc(20% + 20px);
  }
  .description-carousel {
    height: 230px;
  }
} */

.description {
  line-height: 1.2em;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  color: black;
  width: 78%;
  margin: auto;
  height: 8em;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 100px;
}

.title {
  color: black;
  margin-bottom: 2%;
  font-weight: normal;
  padding-top: 5%;
  text-align: center;
  font-family: font;
}

h1.title {
  font-size: 4.5em;
  padding-top: 12%;
  color: black;
  text-decoration: none;
  font-family: font;
}

@keyframes openBox {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
    filter: brightness(0.8);
  }
  50% {
    transform: scale(0.9);
    filter: brightness(1);
  }
  75% {
    transform: scale(1.1);
    filter: brightness(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.open-box {
  animation: openBox 0.6s;
}
