.buyArea {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleOfProduct {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-align: center;
  justify-content: center;
  color: black;
  font-family: font;
}

.product {
  display: flex;
  color: black;
  flex-direction: column;
  align-items: center;
  margin: auto auto;
  border-radius: 5%;
  position: relative;
  padding: 3%;
  width: 70%;
}

.buyscroll {
  max-width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.itemInfo li {
  margin: 10px;
  font-size: 1.2rem;
  color: black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.itemInfo {
  margin-top: 2em;
}

.buyButton {
  border-radius: 5%;
  cursor: pointer;
  padding: 1.2rem;
  margin-bottom: 2em;
  margin-top: 3%;
  background-color: rgb(76, 76, 243);
  border: none;
  width: 50%;
  text-decoration: none;
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.buyButton:hover {
  background-color: rgb(124, 124, 224);
}

.nav-menu {
  width: 100%;
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: -100%;
  transition: 850ms;
  z-index: 1000;
  box-shadow: 0px 0px 20px #daa52050;
}

@media screen and (min-width: 700px) {
  .nav-menu {
    width: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .nav-menu {
    width: 45%;
  }
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
  box-shadow: 0px 0px 20px #daa520;
}

.nav-menu-items {
  width: 100%;
}

span {
  margin-left: 16px;
}

.gameBox {
  margin-top: 5%;
  width: 50%;
  filter: drop-shadow(0px 0px 10px black);
}

.closeButton {
  right: 0;
  width: 5%;
  padding: 2%;
  cursor: pointer;
  align-self: flex-end;
}

.crowd-buytab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  padding: 5%;
  margin-top: 5em;
  text-align: center;
}
