.basic-info-display_image {
  width: 90%;
  max-width: 550px;
  margin: auto;
  margin-top: 10px;
  display: block;
  transition : 0.5s;
  z-index: 100;
}

@media screen and (min-width: 1000px) {
  .basic-info-display_image {
    margin-left: 35%;
  }
}
