.contact-background {
    position: fixed;
	width: 100%;
    height: 100vh;
    display: flex;
	justify-content: center;
    background-color: rgba(0,0,0,0.7);
	align-items: center;
	z-index: 1000;
	transition: 0.6s;
}

.contact-popUp {
    width: 400px;
    height: 250px;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: cabin;
    font-size: 2em;
    flex-direction: column;
}