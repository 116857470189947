.info_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.info_title {
    font-family: sans-serif;
    font-size: 4rem;
    color: black;
    top: 50%;
    left: 50px;
    margin: 15px;
}

.info_info {
    font-family: sans-serif;
    color: black;
    font-size: 3rem;
}

.info_button {
    align-self: center;
    margin: 30px;
    cursor: pointer;
    font-size: 3rem;
    color: grey;
    width: 20%;
    border: none;
    background-color: greenyellow;
    border-radius: 10%;
    box-shadow: 2px 2px 10px green;
}

.info_button:hover {
    transform: scale(1.03);
    background-color: rgb(150,255,40);
}