.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2em;
  background-color: black;
  border-radius: 25px;
  padding: 2em;
  position: relative;
  border: 2px solid #7c4b07;
}

.modal > h1,
input {
  width: 100%;
}

.modal > input {
  border: none;
  border-bottom: 1px solid black;
  padding: 0.5em;
  color: black;
  border-radius: 10px;
}

.modal > img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2em;
  color: white;
  cursor: pointer;
  margin: 0.5em;
}

.google-suggestion-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.google-login-button > img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.modal-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.google-login-button {
  background-color: aliceblue;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.google-login-button:hover {
  background-color: #e0e0e0;
}

.google-login-button > label {
  color: black;
  cursor: pointer;
}

.hr-surrounded-string {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 1em;
  font-size: 2em;
}
