@font-face {
  font-family: font;
  src: url(../fonts/fanta__.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  color: #fff;
  font-family: "Platypi", serif;
}

.main {
  background-color: black;
  min-height: 100vh;
  background-image: radial-gradient(
      white,
      rgba(255, 255, 255, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(white, rgba(255, 255, 255, 0.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 40px),
    radial-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1) 2px,
      transparent 30px
    );
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.font-hashiban {
  font-family: font;
}

.std-button {
  /* margin: 5px 1.5vw; */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  border-radius: 15px;
  background-color: rgba(222, 222, 222, 0.1);
  font-size: clamp(1.7rem, 2.5vw, 2.6rem);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* border-radius: 10px; */
}

.std-button:hover,
.std-button.active {
  text-decoration: none;
  background: #fff;
  color: #7c4b07;
  cursor: pointer;
}

.std-button.wide {
  width: 100%;
}

.std-button.square {
  border-radius: 0%;
}

.std-button.dark {
  background-color: black;
}

.std-button.disabled {
  background-color: rgba(222, 222, 222, 0.1);
  color: rgba(222, 222, 222, 0.5);
  cursor: not-allowed;
}

.std-button-notification {
  height: 16px;
  width: 16px;
  margin-left: 0.4em;
}

html {
  scroll-behavior: smooth;
}

@keyframes lighthue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.glass {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
