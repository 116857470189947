#footer {
	width: 100%;
	padding-top: 1%;
	background-color: black;
	padding-bottom: 1%;
	transition: 0.6s;
	font-family: cabin;
}

/* phones */
@media screen and (max-width:1158px){
	#footer{
		display: inline-block;
		text-align: center;
	}
	#footer nav a {
		line-height: 1.6em;
	}
}

#footer nav {
	display: flex;
	justify-content: right ;
}

.std-button.ig-icon:hover {
	background-color: blueviolet;
}

.std-button.contact-icon:hover {
	@apply bg-blue-500
}